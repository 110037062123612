import * as React from "react";
import Layout from "../components/Layout";

export default () => (
  <Layout>
    <div>
      <h1>NOT FOUND</h1>
    </div>
  </Layout>
);
